var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"bg-white rounded w-50 mr-1 p-1"},[_c('h1',{staticClass:"font-weight-bolder font-medium-5"},[_vm._v(" "+_vm._s('Assets')+" ")]),_vm._l((Object.entries(_vm.assetGroupsByCategory)),function(ref){
var key = ref[0];
var values = ref[1];
return _c('div',{key:key},[_c('h2',{staticClass:"font-weight-bolder font-medium-3"},[_vm._v(" "+_vm._s(key)+" ")]),_c('l-table-list-collector',{ref:(key + "_lTableRef"),refInFor:true,staticClass:"tablePickedItems",attrs:{"is-searchable":false,"module-name":_vm.MODULE_NAME,"table-columns":_vm.packItemTableColumns,"fetched-data":values,"has-footer":false,"show-empty-list-title":"","row-active":{
        filedKey: 'activatedPack',
        classes: 'activeRowColor',
      }},scopedSlots:_vm._u([{key:"cell(state)",fn:function(ref){
      var item = ref.data.item;
return [_c('state-of-asset',{attrs:{"data":item,"with-label":false,"styles":{ color: '#00B139', width: '20px', height: '20px' }}})]}},{key:"cell(asset_id)",fn:function(ref){
      var item = ref.data.item;
return [(_vm.orderType !== _vm.ORDER_TYPES_KV.SR)?_c('div',{staticClass:"d-flex align-items-baseline justify-content-between"},[_c('span',[_vm._v(_vm._s(_vm.getValueFromGivenObjectByKey(item,'asset_id')))])]):_c('div',[(item.asset_id)?_c('sub-rental-assets'):_c('span',[_vm._v(" "+_vm._s(_vm.getValueFromGivenObjectByKey(item, 'asset_id'))+" ")])],1)]}},{key:"cell(name)",fn:function(ref){
      var item = ref.data.item;
return [_vm._v(" "+_vm._s(_vm.getValueFromGivenObjectByKey(item,'product.name'))+" ")]}},{key:"cell(pack_icon)",fn:function(ref){
      var item = ref.data.item;
return [_c('div',{class:("icon-wrapper " + (item.activatedPack && 'active') + "  " + (item.status.toString() !== _vm.assetStates.PICKED.code.toString() && 'disabledPackIcon')),on:{"click":function($event){item.status.toString() === _vm.assetStates.PICKED.code.toString() && _vm.packedAsset(item.id)}}},[_c('feather-icon',{attrs:{"icon":"LBoxIcon","size":"18"}})],1)]}}],null,true)})],1)}),_c('div',{staticClass:"d-flex justify-content-between"},[_c('span'),_c('span',{staticClass:"font-weight-bolder mr-1"},[_vm._v(" Total assets to be packed: "+_vm._s(_vm.orderItems.assets.length)+" ")])])],2)}
var staticRenderFns = []

export { render, staticRenderFns }