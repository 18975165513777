<template>
  <div class="subRentText">
    Sub Rental
  </div>
</template>

<script >

export default {
  name: 'SubRentalAssets',
  setup() {
    return {
    }
  },
}
</script>

<style scoped lang="scss">
.subRentText{
  text-align: center;
  width: 100px;
  padding: 3px 8px;
  border-radius: 5px;
  border: 1px solid #F0AD4E;
  background: #FBE6CA;
  color: #A17940
}
</style>
